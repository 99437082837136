<template>
  <div>
    <div>
      <h1 class="h3 mb-3 text-gray-800">Dashboard</h1>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="py-2 px-3">
              <router-link
                :to="{ name: 'moderator.discussions.create' }"
                class="btn btn-primary btn-sm px-2 py-2 float-right"
                >Start New</router-link
              >
              <h3 class="h4 card-title"><router-link :to="{ name: 'admin.discussions.index' }" class="text-dark">Active Discussions</router-link></h3>
              <table class="table table-responsive border-bottom table-condensed">
                <tbody>
                  <tr v-for="(topic, i) in topics" :key="`discussion-${i}`">
                    <td width="15%">
                      <router-link
                        :to="{
                          name: 'moderator.discussions.show',
                          params: { id: topic.id },
                        }"
                      >
                        <img
                          :src="topic.author.avatar"
                          :alt="topic.title"
                          class="avatar"
                        />
                      </router-link>
                    </td>
                    <td class="border-right">
                      <router-link
                        :to="{
                          name: 'moderator.discussions.show',
                          params: { id: topic.id },
                        }"
                      >
                        <p
                          :class="`mb-0 ${
                            loading ? 'skeleton skeleton-text' : ''
                          }`"
                        >
                          {{ topic.title }}
                        </p>
                        <p class="mb-0">
                          <small class="text-muted">{{ $localizer.parse(topic.date) }}</small>
                        </p>
                      </router-link>
                    </td>
                    <td width="10%">
                      <router-link
                        :to="{
                          name: 'moderator.discussions.show',
                          params: { id: topic.id },
                        }"
                      >
                        <p class="mb-0 text-black">Replies</p>
                        <p class="mb-0">
                          {{ topic.comments }}
                        </p>
                      </router-link>
                    </td>
                  </tr>
                  <tr v-if="topics.length == 0">
                    <td>
                      There is no data to display
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="px-3 py-2">
              <router-link
                :to="{ name: 'moderator.conference.create' }"
                class="btn btn-primary btn-sm px-2 py-2 float-right"
                >Start New</router-link
              >
              <h3 class="h4 card-title"><router-link :to="{ name: 'moderator.conference.index' }" class="text-dark">Daily Conference</router-link></h3>
              <table class="table table-responsive">
                <tbody>
                  <tr v-for="(video, i) in conference" :key="`video-${i}`">
                    <td width="60%">
                      <p :class="loading ? 'skeleton skeleton-text mb-0' : ''">
                        <router-link
                          :class="loading ? 'skeleton skeleton-text' : ''"
                          :to="{
                            name: 'moderator.conference.show',
                            params: { id: video.id },
                          }"
                          >{{ video.title }}</router-link
                        >
                      </p>
                    </td>
                    <td>
                      <p :class="loading ? 'skeleton skeleton-text mb-0' : ''">
                        {{ $localizer.parse(video.date) }}
                      </p>
                    </td>
                  </tr>
                  <tr v-if="conference.length == 0">
                    <td colspan="2">There is no data to display</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      topics: [],
      loading: true,
      conference: [],
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/moderator/home").then((response) => {
        this.topics = response.data.topics;
        this.conference = response.data.conference;
        this.loading = false;
      });
    },
  },
};
</script>
