var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('h1',{staticClass:"h3 mb-3 text-gray-800"},[_vm._v("Dashboard")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"py-2 px-3"},[_c('router-link',{staticClass:"btn btn-primary btn-sm px-2 py-2 float-right",attrs:{"to":{ name: 'moderator.discussions.create' }}},[_vm._v("Start New")]),_c('h3',{staticClass:"h4 card-title"},[_c('router-link',{staticClass:"text-dark",attrs:{"to":{ name: 'admin.discussions.index' }}},[_vm._v("Active Discussions")])],1),_c('table',{staticClass:"table table-responsive border-bottom table-condensed"},[_c('tbody',[_vm._l((_vm.topics),function(topic,i){return _c('tr',{key:("discussion-" + i)},[_c('td',{attrs:{"width":"15%"}},[_c('router-link',{attrs:{"to":{
                        name: 'moderator.discussions.show',
                        params: { id: topic.id },
                      }}},[_c('img',{staticClass:"avatar",attrs:{"src":topic.author.avatar,"alt":topic.title}})])],1),_c('td',{staticClass:"border-right"},[_c('router-link',{attrs:{"to":{
                        name: 'moderator.discussions.show',
                        params: { id: topic.id },
                      }}},[_c('p',{class:("mb-0 " + (_vm.loading ? 'skeleton skeleton-text' : ''))},[_vm._v(" "+_vm._s(topic.title)+" ")]),_c('p',{staticClass:"mb-0"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$localizer.parse(topic.date)))])])])],1),_c('td',{attrs:{"width":"10%"}},[_c('router-link',{attrs:{"to":{
                        name: 'moderator.discussions.show',
                        params: { id: topic.id },
                      }}},[_c('p',{staticClass:"mb-0 text-black"},[_vm._v("Replies")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(topic.comments)+" ")])])],1)])}),(_vm.topics.length == 0)?_c('tr',[_c('td',[_vm._v(" There is no data to display ")])]):_vm._e()],2)])],1)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"px-3 py-2"},[_c('router-link',{staticClass:"btn btn-primary btn-sm px-2 py-2 float-right",attrs:{"to":{ name: 'moderator.conference.create' }}},[_vm._v("Start New")]),_c('h3',{staticClass:"h4 card-title"},[_c('router-link',{staticClass:"text-dark",attrs:{"to":{ name: 'moderator.conference.index' }}},[_vm._v("Daily Conference")])],1),_c('table',{staticClass:"table table-responsive"},[_c('tbody',[_vm._l((_vm.conference),function(video,i){return _c('tr',{key:("video-" + i)},[_c('td',{attrs:{"width":"60%"}},[_c('p',{class:_vm.loading ? 'skeleton skeleton-text mb-0' : ''},[_c('router-link',{class:_vm.loading ? 'skeleton skeleton-text' : '',attrs:{"to":{
                          name: 'moderator.conference.show',
                          params: { id: video.id },
                        }}},[_vm._v(_vm._s(video.title))])],1)]),_c('td',[_c('p',{class:_vm.loading ? 'skeleton skeleton-text mb-0' : ''},[_vm._v(" "+_vm._s(_vm.$localizer.parse(video.date))+" ")])])])}),(_vm.conference.length == 0)?_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("There is no data to display")])]):_vm._e()],2)])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }